import RecordRTC, { MediaStreamRecorder } from "recordrtc";

// utils
import { getInputDevice } from "../../views/recorder/lib/devices";

// class
export class Recorder {
  recorder?: RecordRTC;
  stream?: MediaStream;

  isRecording: boolean;
  isPaused: boolean;

  constructor() {
    this.isRecording = false;
    this.isPaused = false;
  }

  // get stream
  // create recorder
  // init booleans
  // assign vars
  async start(video = false) {
    if (this.isRecording || this.isPaused)
      throw new Error("Can't start when already recording or paused.");

    // get device
    const device = getInputDevice();

    // init stream
    const constraints: MediaStreamConstraints = video
      ? {
          video: { facingMode: { exact: "user" }, width: 1920, height: 1080 },
          audio: { deviceId: { exact: device || undefined } },
        }
      : {
          video: false,
          audio: {
            channelCount: 1,
            echoCancellation: false,
            deviceId: { exact: device || undefined },
          },
        };
    const stream = await navigator.mediaDevices.getUserMedia(constraints);

    // init recorder
    const opts: RecordRTC.Options = video
      ? {
          recorderType: MediaStreamRecorder,
          mimeType: "video/mp4",
          type: "video",
        }
      : {
          recorderType: MediaStreamRecorder,
          mimeType: "audio/wav",
          type: "audio",
        };
    const recorder = new RecordRTC(stream, opts);

    // start
    recorder.startRecording();
    this.isRecording = true;

    // save vars
    this.stream = stream;
    this.recorder = recorder;
  }

  pause() {
    this.recorder?.pauseRecording();
    this.isPaused = true;
  }
  resume() {
    this.recorder?.resumeRecording();
    this.isPaused = false;
  }

  // stop recorder
  // stop stream tracks
  // set booleans to false
  // resolve with blob
  // destroy recorder and clear rest
  async stop() {
    return new Promise<Blob | undefined>((res, _rej) =>
      this.recorder?.stopRecording(() => {
        this.stream?.getTracks().forEach(t => t.stop());
        this.isRecording = false;
        this.isPaused = false;
        res(this.recorder?.getBlob());

        this.recorder?.destroy();
        this.stream = undefined;
        this.recorder = undefined;
      }),
    );
  }
}

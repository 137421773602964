export type Pause = { start: number; length: number };
export type Status = {
  time: number;
  pitch: number;
  note: string;
  volume: number;
  pause: Pause | null;
};

export class Analyzer {
  private ctx: AudioContext | null;
  private source: MediaStreamAudioSourceNode | null;
  private analyserNode: AnalyserNode | null;

  private sampleRate: number;
  private intBuffer: Uint8Array;
  private buffer: Float32Array;

  // update interval
  private intervalLength = 100;
  private interval: any;

  // pause calculation
  private recordingStart = 0;
  private inPause = false;
  private pauseThreshold = 0.25;
  private minPauseLength = 1000;
  private prevPauseTime = 0;

  async start(stream?: MediaStream, cb: (data: Status) => void = () => {}) {
    if (!this.ctx) this.ctx = new AudioContext();

    // stream
    if (!stream)
      stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          echoCancellation: true,
          sampleRate: this.sampleRate,
        },
        video: false,
      });

    // source
    this.source = this.ctx.createMediaStreamSource(stream);

    // analyzer
    this.analyserNode = this.ctx.createAnalyser();
    this.analyserNode.fftSize = this.sampleRate;
    this.analyserNode.minDecibels = -127;
    this.analyserNode.maxDecibels = 0;
    this.analyserNode.smoothingTimeConstant = 0.1;

    // node network
    this.source.connect(this.analyserNode);

    // interval
    let time = 0;
    this.recordingStart = Date.now();
    this.interval = setInterval(() => {
      const volume = this.getVolume();

      let pause: Pause | null = null;
      if (volume < this.pauseThreshold && !this.inPause) {
        this.inPause = true;
        this.prevPauseTime = Date.now();
      }
      if (volume >= this.pauseThreshold && this.inPause) {
        this.inPause = false;
        const pauseLength = Date.now() - this.prevPauseTime;
        if (pauseLength >= this.minPauseLength)
          pause = {
            start: this.prevPauseTime - this.recordingStart,
            length: pauseLength,
          };
      }

      cb({ ...this.getPitch(), time, volume, pause });
      time += this.intervalLength;
    }, this.intervalLength);
  }

  async destroy() {
    await this.ctx?.close();
    this.source = null;
    this.analyserNode = null;
    clearInterval(this.interval);
  }

  getPitch() {
    this.analyserNode?.getFloatTimeDomainData(this.buffer);
    const pitch = autoCorrelate(
      this.buffer,
      this.ctx?.sampleRate || this.sampleRate,
    );
    const note = noteFromPitch(pitch);
    return { pitch, note };
  }

  getVolume() {
    this.analyserNode?.getByteFrequencyData(this.intBuffer);
    return (
      this.intBuffer.reduce((cum, cur) => cum + cur, 0) /
      (this.sampleRate / 2) /
      127
    );
  }

  constructor(sampleRate = 2048) {
    this.ctx = new AudioContext();
    this.source = null;
    this.analyserNode = null;
    this.sampleRate = sampleRate;
    this.buffer = new Float32Array(sampleRate);
    this.intBuffer = new Uint8Array(sampleRate / 2);
  }
}

function autoCorrelate(buf: Float32Array, sampleRate: number) {
  let SIZE = buf.length;
  let rms = 0;

  for (let i = 0; i < SIZE; i++) rms += buf[i] * buf[i];

  rms = Math.sqrt(rms / SIZE);
  if (rms < 0.01)
    // not enough signal
    return -1;

  let r1 = 0,
    r2 = SIZE - 1;
  const thres = 0.2;
  for (let i = 0; i < SIZE / 2; i++)
    if (Math.abs(buf[i]) < thres) {
      r1 = i;
      break;
    }
  for (let i = 1; i < SIZE / 2; i++)
    if (Math.abs(buf[SIZE - i]) < thres) {
      r2 = SIZE - i;
      break;
    }

  buf = buf.slice(r1, r2);
  SIZE = buf.length;

  const c = new Array(SIZE).fill(0);
  for (let i = 0; i < SIZE; i++)
    for (let j = 0; j < SIZE - i; j++) c[i] = c[i] + buf[j] * buf[j + i];

  let d = 0;
  while (c[d] > c[d + 1]) d++;
  let maxval = -1,
    maxpos = -1;
  for (let i = d; i < SIZE; i++)
    if (c[i] > maxval) {
      maxval = c[i];
      maxpos = i;
    }
  let T0 = maxpos;

  const x1 = c[T0 - 1],
    x2 = c[T0],
    x3 = c[T0 + 1];
  const a = (x1 + x3 - 2 * x2) / 2;
  const b = (x3 - x1) / 2;
  if (a) T0 = T0 - b / (2 * a);

  return sampleRate / T0;
}
const noteStrings = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B",
];
function noteFromPitch(frequency: number) {
  const noteNum = 12 * (Math.log(frequency / 440) / Math.log(2));
  const idx = Math.round(noteNum) + 69;
  return noteStrings[idx % 12];
}

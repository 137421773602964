























































































































































import Vue from "vue";
import { Action } from "vuex-class";
import { Component } from "vue-property-decorator";

// models
import { Presentation, Recording, Session, Timestamp } from "@/core/models";

// utils
import {
  LiveTranscription,
  TranscriptionSlide,
} from "@/core/utils/transcription";
import api from "@/core/utils/api";
import { Analyzer, Pause } from "@/core/utils/analyzer";
import { Recorder } from "@/core/utils/wavRecorder";
import { convertDuration } from "@/views/recorder/components/utils";

// components
import { ImageSlider } from "@/components/common";
import PresentationSelect from "@/views/recorder/components/PresentationSelect.vue";
import LangSwitcher from "@/views/recorder/components/LangSwitcher.vue";
import DeviceSelect from "@/views/recorder/components/DeviceSelect.vue";
import VolumeTester from "@/views/recorder/components/volume-tester/VolumeTester.vue";

@Component({
  components: {
    ImageSlider,
    LangSwitcher,
    DeviceSelect,
    VolumeTester,
    PresentationSelect,
  },
})
export default class Transcription extends Vue {
  @Action("sessions/addSession") addSession!: (s: Session) => void;

  recorder = new Recorder();
  transcription = new LiveTranscription();

  // audio analyzer
  analyzer = new Analyzer();

  // pitch
  currentPitch = 0;
  currentNote = "";
  pitchAnalysis: { time: number; value: number }[] = [];
  avgPitch: number | null = null;

  // pauses
  currentVolume = 0;
  pauseAnalysis: Pause[] = [];
  avgPauseLength: number | null = null;
  totalPauseTime: number | null = null;

  // misc
  lang = "en";
  paused = false;
  mode = "audio";
  url: string | null = null;
  recording: Recording | null = null;
  slides: TranscriptionSlide[] | null = null;

  // presentation slides
  slideIndex = 0;
  shouldReset = false;
  pres: Presentation | null = null;
  timestamps: Timestamp[] = [];
  get images() {
    return this.pres?.Slides.map(x => x.Uri) || [];
  }
  slideChanged(idx: number) {
    this.slideIndex = idx;
    if (this.running) this.addSlide();
  }

  // slide getters
  get totalWpm() {
    if (!this.slides) return 0;
    const wpm =
      this.slides.map(x => x.wpm).reduce((cum, cur) => cum + cur, 0) /
      this.slides.length;
    return Math.round(wpm * 100) / 100;
  }
  get totalOccurances() {
    const map = new Map<string, number>();
    this.slides?.forEach(x => {
      x.occurrances.forEach(y => {
        map.set(y[0], (map.get(y[0]) || 0) + y[1]);
      });
    });
    return [...map.entries()].sort((a, b) => b[1] - a[1]);
  }

  // duration stuff
  duration = 0;
  durationInterval: any;
  get durationFormatted() {
    return convertDuration(this.duration);
  }

  get running() {
    return this.recorder.isRecording && this.transcription.running;
  }

  uploading = false;
  async upload() {
    if (!this.recording) return;
    this.uploading = true;
    try {
      // create data object
      const words =
        this.recording.words?.map(x => ({
          text: x[0],
          occurrences: x[1],
          type: "default",
        })) || [];

      const data = new FormData();
      data.append("type", this.recording.type);
      data.append("audioFile", this.recording.audioBlob);
      data.append("videoFile", this.recording.videoBlob || null);
      data.append("title", this.recording.title);
      data.append("locale", this.recording.locale);
      data.append("wpm", JSON.stringify(this.recording.wpm));
      data.append("text", JSON.stringify(this.recording.text));
      data.append("words", JSON.stringify(words));
      data.append("slides", JSON.stringify(this.recording.slides));
      data.append("duration", JSON.stringify(this.recording.duration));
      data.append("timestamps", JSON.stringify(this.recording.timestamps));
      data.append("recordedAt", this.recording.recordedAt);
      data.append("pitchAnalysis", JSON.stringify(this.pitchAnalysis));
      data.append("avgPitch", (this.avgPitch || 0).toString());
      data.append("pauseAnalysis", JSON.stringify(this.pauseAnalysis));
      data.append("avgPauseLength", (this.avgPauseLength || 0).toString());
      data.append("totalPauseTime", (this.totalPauseTime || 0).toString());
      data.append(
        "presentationId",
        this.recording.presentationId?.toString() || "0",
      );

      // send to api
      const session = (await api.post("/api/Recordings/New", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })) as Session;
      this.addSession(session);
    } catch (error) {
      console.log(error);
    }
    this.uploading = false;
  }

  async toggle() {
    if (this.running) {
      // stop + get blob
      const blob = await this.recorder.stop();

      // clear duration
      clearInterval(this.durationInterval);

      // clear analyzer
      this.analyzer.destroy();

      // get slides
      this.slides = await this.transcription.stop();

      // make slides
      console.debug(this.slides.length, this.timestamps.length);
      for (let i = 0; i < this.timestamps.length; i++) {
        // slide images
        this.slides[i].image = this.images[this.timestamps[i].slideIndex];

        // slide consts
        const currTime = this.timestamps[i].time * 1000;
        const nextTime =
          i + 1 >= this.timestamps.length
            ? (this.duration + 1) * 1000
            : this.timestamps[i + 1].time * 1000;

        console.debug("[times]", currTime, nextTime);

        // slide pitches
        const pitchValues = this.pitchAnalysis.filter(
          x => x.time >= currTime && x.time < nextTime,
        );
        const pitchSum = pitchValues
          .map(x => x.value)
          .reduce((cum, cur) => cum + cur, 0);
        this.slides[i].avgPitch = Math.round(pitchSum / pitchValues.length);

        // slide pauses
        const pauses = this.pauseAnalysis.filter(
          x => x.start >= currTime && x.start < nextTime,
        );
        const totalPauseTime =
          Math.round(
            pauses.map(x => x.length).reduce((cum, cur) => cum + cur, 0) / 100,
          ) / 10;
        this.slides[i].avgPauseLength =
          Math.round((totalPauseTime / pauses.length) * 10) / 10;
        this.slides[i].totalPauseTime = totalPauseTime;
        this.slides[i].pausesMade = pauses.length;

        console.debug("[pitch values]", pitchValues);
        console.debug("[pause values]", pauses);
      }

      // average pitch
      this.avgPitch = Math.round(
        this.pitchAnalysis
          .map(x => x.value)
          .reduce((cum, cur) => cum + cur, 0) / this.pitchAnalysis.length,
      );

      // average pause length
      this.totalPauseTime = this.pauseAnalysis
        .map(x => x.length)
        .reduce((cum, cur) => cum + cur, 0);
      const totalPauses = this.pauseAnalysis.length;
      this.avgPauseLength =
        Math.round(this.totalPauseTime / totalPauses / 100) / 10;
      if (isNaN(this.avgPauseLength)) this.avgPauseLength = 0;

      // create url
      this.url = URL.createObjectURL(blob);

      // create recording
      const now = new Date();
      this.recording = {
        id: 0,
        blob,
        audioBlob: null,
        videoBlob: null,
        type: this.mode as any,
        locale: this.lang as any,
        timestamps: this.timestamps,
        recordedAt: now.toISOString(),
        presentationId: this.pres?.ID,
        title: `recording_${now.toLocaleDateString()}`,

        wpm: this.totalWpm,
        slides: this.slides,
        duration: this.duration,
        words: this.totalOccurances,
        text: this.transcription.text
          .trim()
          .replaceAll("\r\n", " ")
          .replaceAll('"', ""),
      };
    } else {
      // reset recorder
      this.reset();

      // add timestamp if a presentation is selected
      if (this.pres)
        this.timestamps.push({
          time: 0,
          index: 0,
          slideIndex: this.slideIndex,
          slideURI: this.images[this.slideIndex],
        });

      // start duration counter
      this.durationInterval = setInterval(() => (this.duration += 0.01), 10);

      // start recorder and transcription
      await this.recorder.start(this.mode === "video");
      this.transcription.start(
        this.recorder.stream,
        this.lang === "en" ? "en-US" : "de-DE",
      );

      // setup analyzer
      if (this.recorder.stream)
        this.analyzer.start(
          // this.recorder.stream,
          undefined,
          ({ time, pitch, note, volume, pause }) => {
            this.currentVolume = volume;
            if (pause) this.pauseAnalysis.push(pause);
            if (pitch >= 50 && pitch <= 600) {
              this.currentPitch = Math.round(pitch);
              this.currentNote = note;
              this.pitchAnalysis.push({ time, value: pitch });
            }
          },
        );

      // show video feed
      if (this.mode === "video") {
        const el = document.querySelector("#video") as HTMLVideoElement;
        el.removeAttribute("src");
        el.srcObject = this.recorder.stream || null;
        el.muted = true;
        el.controls = false;
        el.play();
      }
    }
  }

  reset() {
    this.duration = 0;
    this.slideIndex = 0;
    this.timestamps = [];
    this.shouldReset = true;
    this.url = null;
    this.slides = null;
    this.recording = null;
    this.pitchAnalysis = [];
    this.pauseAnalysis = [];
    this.avgPitch = null;
    this.avgPauseLength = null;
    this.totalPauseTime = null;
  }

  togglePause() {
    if (this.paused) {
      this.paused = false;
      this.recorder.resume();
      this.transcription.resume();
      this.durationInterval = setInterval(() => (this.duration += 1), 1000);
    } else {
      this.paused = true;
      this.recorder.pause();
      this.transcription.pause();
      clearInterval(this.durationInterval);
    }
  }

  addSlide() {
    console.debug("[add slide] Add slide called");
    this.transcription.addSlide();
    this.timestamps.push({
      time: this.duration,
      slideIndex: this.slideIndex,
      index: this.transcription.slide,
      slideURI: this.images[this.slideIndex],
    });
  }
}
